<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <!-- <img src="../../../assets/images/logo.svg" /> -->
                </div>
                <h4 class="text-center">Get started with SpotterChat!!</h4>
                <h6 class="font-weight-light text-center">Sign in to continue.</h6>
                <form class="pt-3">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control form-control-lg"
                      placeholder="Username"
                      v-model="email"
                      required
                    >
                     <b-form-invalid-feedback v-if="!email.required" id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>

                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control form-control-lg"
                      placeholder="Password"
                      v-model="password"
                      required
                    >
                     <b-form-invalid-feedback v-if="!password.required" id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                  </div>
                  <!-- <div class="mt-3">
                    <button
                      class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                      @click="chatLogin()"
                    >SIGN IN</button>
                  </div>-->
                  <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input">
                        Keep me signed in
                        <i class="input-helper"></i>
                      </label>
                    </div>
                    <a href="javascript:void(0);" class="auth-link text-black">Forgot password?</a>
                  </div>
                  <div class="mb-2">
                    <button
                      type="button"
                      class="btn btn-block btn-google auth-form-btn"
                      @click="chatLogin()"
                    >
                      <!-- <i class="ti-google mr-2"></i> -->
                      Sign In
                    </button>
                  </div>
                  <!-- <div class="text-center mt-4 font-weight-light">
                    Don't have an account?
                    <router-link to="/auth-pages/register" class="text-primary">Create</router-link>
                  </div>-->
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import ZAPI from "@/zulip";
import API from "@/api";
import HFunction from "@/helper";
import Snotify, { SnotifyPosition } from "vue-snotify";
import {helpers, required, minLength, email} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
const zulipInit = require("zulip-js");
var ZULIP_URL = "https://techleadz.zulipchat.com";
var qs = require("qs");

const options = {
  toast: {
    position: SnotifyPosition.rightTop
  }
};

Vue.use(Snotify, options);

export default {
  name: "chat-login",
  props: ['userEmail'],
  data() {
    return {
      isDisabled: false,
      email: "",
      password: ""
    };
  },
  mixins: [validationMixin],
   validations: {
    email: {
      required
    },
    password: {
      required: required,
    },
  },
  methods: {
    chatLogin() {
      const data = {
        email: this.userEmail,
        password: this.password
      };
      API.refreshApiKey(data, data => {
        this.$EventBus.$emit('userLoggedIn');
      },
      err => {
        console.log(err);
        this.$snotify.error(err.response.data.message);
      });
    }
  }
};
</script>
