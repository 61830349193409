<template>
  <section>
    <div>
      <b-modal ref="add-stream" hide-footer id="add-stream" :title="$t('add-stream')" class="modal">
        <b-tabs content-class="mt-3" align="center">
          <b-tab :title="$t('add-stream')" active>
            <form class="forms-sample" @submit.stop.prevent="onAddStream" autocomplete="off">
              <b-form-group :label="$t('stream-name')" label-for="name">
                <b-form-input
                  type="text"
                  id="name"
                  name="name"
                  :placeholder="$t('stream-name')"
                  v-model="$v.form.subscriptions.name.$model"
                  :state="$v.form.subscriptions.name.$dirty ? !$v.form.subscriptions.name.$error : null"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="$t('stream-description')" label-for="description">
                <b-form-input
                  type="text"
                  id="description"
                  name="description"
                  :placeholder="$t('stream-description')"
                  v-model="$v.form.subscriptions.description.$model"
                  :state="$v.form.subscriptions.description.$dirty ? !$v.form.subscriptions.description.$error : null"
                  aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group :label="$t('stream-users')" label-for="users">
                <v-select
                  multiple
                  :options="filterClientUsersOptions"
                  :reduce="option => option.email"
                  v-model="form.principals"
                  label="email"
                ></v-select>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox name="check-button" switch disabled value="false">@{{ $t('private-button') }}</b-form-checkbox>
                <p style="color: red;">{{ $t('private-message') }}</p>
              </b-form-group>
              <b-button
                type="submit"
                class="mt-3"
                style="background: #7367f0 !important; border: #7367f0 !important;"
                variant="success"
                block
              >{{ $t('add-stream-btn') }}</b-button>
            </form>
          </b-tab>

          <!-- Update Form -->
          <b-tab :title="$t('update-stream')">
            <form class="forms-sample" @submit.stop.prevent="onUpdateStream" autocomplete="off">
              <b-form-group :label="$t('select-stream')" label-for="Select Stream">
                <b-form-select @change="triggerUsers">
                  <option :value="null" disabled>-- Please select an option --</option>
                  <option  v-for="stream in streams" :value="stream" :key="stream.stream_id">
                    {{ stream.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
              </b-form-group>
              <div class="row">
                <div class="col-md-12 text-center">
                  <rotate-square2 v-if="loadingStreamUsers"></rotate-square2>
                </div>
              </div>
              <div v-if="!loadingStreamUsers">
                <div class="row" v-for="user in subscribedUsers" :key="user.user_id">
                  <div class="col-md-8">
                    {{user.email}}
                  </div>
                  <div class="col-md-4">
                    <b-button type="button" class="mb-2" size="sm" style="background: #7367f0 !important; border: #7367f0 !important;" variant="success" small block @click="unsubscribeStreamUser(user.email)">Remove</b-button>
                  </div>
                  <!-- <p v-for="user in subscribedUsers" :key="user.user_id">{{user.email}}</p> -->
                </div>
              </div>
              <b-form-group :label="$t('stream-users')" label-for="users">
                    <v-select
                      multiple
                      :options="filterClientUsersOptions"
                      :reduce="option => option.email"
                      v-model="updateForm.principals"
                      label="email"
                    ></v-select>
              </b-form-group>
              <!-- <p>selected: {{updateForm.principals}}</p> -->
              <b-button
                type="submit"
                class="mt-3"
                style="background: #7367f0 !important; border: #7367f0 !important;"
                variant="success"
                block
              >{{ $t('update-stream-btn') }}</b-button>
            </form>
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import ZAPI from "@/zulip";
import API from "@/api";
import HFunction from "@/helper";
import Snotify, { SnotifyPosition } from "vue-snotify";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  requiredIf,
  sameAs
} from "vuelidate/lib/validators";
import {RotateSquare2} from 'vue-loading-spinner'
const zulipInit = require("zulip-js");
var ZULIP_URL = "https://techleadz.zulipchat.com";
var qs = require("qs");

const options = {
  toast: {
    position: SnotifyPosition.rightTop
  }
};

Vue.use(Snotify, options);

export default {
  name: "manage-streams",
  props: ["userEmail", "users", "filterClientUsers", "filterClientUsersOptions", "streams", "streamOptions"],
  mixins: [validationMixin],
  components: {
    RotateSquare2
  },
  data() {
    return {
      isDisabled: false,
      form: {
        subscriptions: {
          name: "",
          description: ""
        },
        principals: [],
        name: "",
        stream_desc: ""
      },
      updateForm: {
        subscriptions: {
          name: "",
          // description: ""
        },
        principals: [],
        // name: "",
        // stream_desc: ""
      },
      streamUsersIds: [],
      subscribedUsers: [],
      selectedStream: [],
      loadingStreamUsers: false
    };
  },
  validations: {
    form: {
      subscriptions: {
        name: {
          required
        },
        description: {
          required
        }
      }
    },
    updateForm: {
      subscriptions: {
        name: {
          required
        },
        // description: {
        //   required
        // }
      }
    }
  },
  methods: {
    onAddStream() {
      console.log("inside form submit");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // this.isDisabled = true;
      if (this.form.subscriptions.name != "") {
        let data = {
          subscriptions: JSON.stringify(this.form.subscriptions),
          principals: this.form.principals,
          announce: "false",
          is_web_public: "false"
        };
        API.addStream(
          data,
          data => {
            if (data.status === 200) {
              this.$snotify.success(data.message, {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true
              });

              this.form.subscriptions.name = "";
              this.form.subscriptions.description = "";
              // this.allSelected = false;
              this.form.principals = [];
               this.subscribedUsers = [],
              this.selectedStream = [],
              this.$v.form.$reset();
              this.$refs["add-stream"].hide();
              this.$EventBus.$emit("getAllStreams");
              // this.isDisabled = false;
            }
          },
          err => {
            console.log(err);
            this.$snotify.error(err.response.data.message);
            // this.isDisabled = false;
          }
        );
      } else {
        this.$snotify.warning("Please fill required fields");
        this.isDisabled = false;
      }
    },
    onUpdateStream() {
      this.$v.updateForm.$touch();
      if (this.$v.updateForm.$anyError) {
        return;
      }
      if (this.updateForm.subscriptions.name != "") {
        let data = {
          subscriptions: JSON.stringify(this.updateForm.subscriptions),
          principals: this.updateForm.principals,
          announce: "false",
          is_web_public: "false"
        };
        API.addStream(
          data,
          data => {
            if (data.status === 200) {
              this.$snotify.success('Stream Updated Successfully');
              this.updateForm.subscriptions.name = "";
              this.updateForm.subscriptions.description = "";
              // this.allSelected = false;
              this.updateForm.principals = [];
              this.subscribedUsers = [],
              // this.selectedStream = [],
              this.$v.updateForm.$reset();
              // this.$refs["add-stream"].hide();
              this.triggerUsers(this.selectedStream);
              this.$EventBus.$emit("getAllStreams");
              // this.isDisabled = false;
            }
          },
          err => {
            this.$snotify.error(data.message, {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true
            });
            // this.isDisabled = false;
          }
        );
      } else {
        this.$snotify.warning("Please fill required fields");
        this.isDisabled = false;
      }
    },
    triggerUsers(event) {
      this.loadingStreamUsers = true;
      this.selectedStream = event;
      this.updateForm.subscriptions.name = event.name;
      var principals = [];
      principals.push(event);
      var data = {
        email: this.userEmail,
        stream_id: event.stream_id
      }
      ZAPI.getStreamSubscribers(
        data,
        async data => {
          if (data.status === 200) {
            this.streamUsersIds = data.data;
            this.subscribedUsers = this.users.filter(o1 => this.streamUsersIds.some(o2 => o1.user_id === o2.user_id));
            this.loadingStreamUsers = false;
          } else {
            return false;
          }
        },
        err => {}
      );
    },
    unsubscribeStreamUser(email){
      var principals = [];
      principals.push(email);
      var data = {
        email: email,
        subscriptions: this.updateForm.subscriptions.name,
        principals: principals,
      }
      ZAPI.unsubscribeStreamUser(
        data,
        async data => {
          if (data.status === 200) {
            this.$snotify.success(data.message);
            this.triggerUsers(this.selectedStream);
          } else {
            return false;
          }
        },
        err => {}
      );
    }
  },
  mounted() {
  }
};
</script>
