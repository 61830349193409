import Vue from "vue";


var ZULIP_URL = 'https://spotter.chat/api'
var ZULIP_JSON = 'https://spotter.chat/json'

// var API_URL = 'http://watch-spotter.test/api/'
// var API_URL_CIT = 'http://watch-spotter.test/api/citizen/'

var API_URL = process.env.VUE_APP_ENDPOINT + '/api/'
var API_URL_CIT = process.env.VUE_APP_ENDPOINT + '/api/citizen/'

// var API_URL = 'https://test.watchspotter.app/api/'
// var API_URL_CIT = 'https://test.watchspotter.app/api/citizen/'

// var API_URL = '/api/'
// var API_URL_CIT = '/api/citizen/'

import axios from 'axios'
import router from "./router";

window.axios = axios
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const ZAPI = {

    uploadFileToServer(data, cb, errorCB) {
        axios.post(API_URL + 'v1/user_uploads', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(resp => {
            cb(resp.data)
        }).catch(err => {
            console.log(err)
        })
    },
    event(data, cb, errorCB) {
        if(data.spotterAuth) {
            var headers = {
                'Authorization': `Bearer ${data.spotterAuth}`, 
                'Content-Type': 'application/json'
            }
        }
        axios.post(API_URL + `event`, data,  { headers: headers })
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getStreamSubscribers(data, cb, errorCB) {
        axios.post(API_URL + `getStreamSubscribers`, data)
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    unsubscribeStreamUser(data, cb, errorCB) {
        axios.post(API_URL + `unsubscribeStreamUser`, data)
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
}

const ZFunctions = {

    scrollToBottom(id) {
        const element = document.getElementById('chat-content');
        element.scrollTop = element.scrollHeight;
    }
}
export { ZFunctions };
export default ZAPI