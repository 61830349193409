import Vue from "vue";


var ZULIP_URL = 'https://techleadz.zulipchat.com/api'

import axios from 'axios'

window.axios = axios

const HFunction = {

    scrollToBottom(id) {
        const element = document.getElementById(id);
        element.scrollTop = element.scrollHeight;
    },
    scrollSmoothlyToBottom(id) {
        var element = document.getElementById('chat-content');
        element.scrollIntoView();
    },

}

export default HFunction
